import React, { useState, useRef } from 'react';
import './Styles.css';
import PriceDetailSheet from './PriceDetailSheet';

function OutstationDriverComponent() {
    const [showPriceDetail, setShowPriceDetail] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        mobileNumber: '',
        date: '',
        time: '',
        vehicleType: '',
        pickupAddress: '',
        dropAddress: '',
        tripType: 'One Way Trip',
        selectedDistance: '', // Default selected distance
        selectedDays: '', // Default selected number of days
    });
    const [selectedTripType, setSelectedTripType] = useState('One Way Trip');
    const dateInputRef = useRef(null); // Ref for the date input
    const timeInputRef = useRef(null); // Ref for the time input
    const [formSubmitted, setFormSubmitted] = useState(false); // State variable to track form submission

    const togglePriceDetail = () => {
        setShowPriceDetail(!showPriceDetail);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleTripTypeChange = (tripType) => {
        setFormData({
            ...formData,
            tripType: tripType,
            selectedDistance: '', // Reset selected distance on trip type change
            selectedDays: '', // Reset selected number of days on trip type change
        });
        setSelectedTripType(tripType);
    };

    const handleHourChange = (e) => {
        setFormData({
            ...formData,
            selectedDistance: e.target.value
        });
    };

    const handleDayChange = (e) => {
        setFormData({
            ...formData,
            selectedDays: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare data to send to backend
        const dataToSend = {
            ...formData,
            amount: calculateAmount(),
            componentName: 'OutstationDriverComponent'
        };

        // Send data to backend
        try {
            const response = await fetch('https://dddbackend.vercel.app/api/v1/createddd', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            });
            const responseData = await response.json();
            console.log(responseData);
            if (responseData.success) {
                setFormSubmitted(true);
                resetForm(); // Reset the form fields
                alert('Entry submitted');
            } else {
                alert('Fill the form carefully');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const resetForm = () => {
        setFormData({
            fullName: '',
            mobileNumber: '',
            date: '',
            time: '',
            vehicleType: '',
            pickupAddress: '',
            dropAddress: '',
            tripType: 'One Way Trip',
            selectedDistance: '',
            selectedDays: '',
        });
        setSelectedTripType('One Way Trip');
        dateInputRef.current.value = '';
        timeInputRef.current.value = '';
    };

    const calculateAmount = () => {
        let amount = 0;
        const { selectedDistance, selectedDays } = formData;

        if (formData.tripType === 'One Way Trip') {
            switch (selectedDistance) {
                case '100-150 Kms':
                    amount = 1150;
                    break;
                case '150-200 Kms':
                    amount = 1300;
                    break;
                case '200-250 Kms':
                    amount = 1450;
                    break;
                case '250-300 Kms':
                    amount = 1600;
                    break;
                default:
                    amount = 0;
            }
        } else {
            amount = selectedDays * 1100; // For Round Trip, amount depends on number of days
        }
        return amount;
    };

    const handleDateInputFocus = () => {
        dateInputRef.current.type = 'date'; // Change input type to date when focused
    };

    const handleDateInputBlur = () => {
        if (dateInputRef.current.value === '') {
            dateInputRef.current.type = 'text'; // Change input type back to text if no date is selected
        }
    };

    const handleTimeInputFocus = () => {
        timeInputRef.current.type = 'time'; // Change input type to time when focused
    };

    const handleTimeInputBlur = () => {
        if (timeInputRef.current.value === '') {
            timeInputRef.current.type = 'text'; // Change input type back to text if no time is selected
        }
    };

    return (
        <div className="OutstationDriverComponent">
            <div className="CostEstimation"><h3>Cost Estimation For Outstation Driver</h3></div>
            <div className="TripTypeButton">
                <button
                    className={selectedTripType === 'One Way Trip' ? 'selected' : ''}
                    onClick={() => handleTripTypeChange('One Way Trip')}
                >
                    One Way Trip
                </button>
                <button
                    className={selectedTripType === 'Round Trip' ? 'selected' : ''}
                    onClick={() => handleTripTypeChange('Round Trip')}
                >
                    Round Trip
                </button>
            </div>

            {formData.tripType === 'One Way Trip' && (
                <div className="CostPerHour">
                    <div>
                        <h5>Kilometers</h5>
                    </div>
                    <div className="CostPerDistanceDropDown">
                        <select value={formData.selectedDistance} onChange={handleHourChange}>
                            <option value="Select"> Select</option>
                            <option value="100-150 Kms">100-150 Kms</option>
                            <option value="150-200 Kms">150-200 Kms</option>
                            <option value="200-250 Kms">200-250 Kms</option>
                            <option value="250-300 Kms">250-300 Kms</option>
                        </select>
                    </div>
                </div>
            )}

            {formData.tripType === 'Round Trip' && (
                <div className="CostPerHour">
                    <div>
                        <h5>No. Of Days</h5>
                    </div>
                    <div className="CostPerDistanceDropDown">
                        <select value={formData.selectedDays} onChange={handleDayChange}>
                            <option value="Select"> Select</option>
                            <option value="1">1 Day</option>
                            <option value="2">2 Days</option>
                            <option value="3">3 Days</option>
                            <option value="4">4 Days</option>
                            <option value="5">5 Days</option>
                            <option value="6">6 Days</option>
                            <option value="7">7 Days</option>
                        </select>
                    </div>
                </div>
            )}

            <div className="Price">
                <div className="Amount">{calculateAmount()} Rs</div>
            </div>
            <div className="ViewButton">
                <div> <button onClick={togglePriceDetail}>View Details</button></div>
            </div>
            {showPriceDetail && (
                <div className="ModalOverlay">
                    <div className="ModalContent">
                        <PriceDetailSheet />
                        <button className="CloseButton" onClick={togglePriceDetail}>X</button> {/* Close button */}
                    </div>
                </div>
            )}
            {formSubmitted}
            <form onSubmit={handleSubmit}>
                <div className="Form">
                    <div className="NameAndNumber">
                        <div>
                            <input type="text" placeholder="Full Name" name="fullName" value={formData.fullName} onChange={handleInputChange} />
                        </div>
                        <div>
                            <input type="tel" placeholder="Mobile Number" name="mobileNumber" value={formData.mobileNumber} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="DateTimeAndVehicleType">
                        <div className='OutstationDateAndTime'>
                            <input type="text" placeholder="Enter Date" name="date" ref={dateInputRef} onFocus={handleDateInputFocus} onBlur={handleDateInputBlur} value={formData.date} onChange={handleInputChange} />

                            <input type="text" placeholder="Enter Time" name="time" ref={timeInputRef} onFocus={handleTimeInputFocus} onBlur={handleTimeInputBlur} value={formData.time} onChange={handleInputChange} />
                        </div>
                        <div className='OutstationVehicleType'>
                            <select name="vehicleType" value={formData.vehicleType} onChange={handleInputChange}>
                                <option value="" disabled selected>Vehicle Type</option>
                                <option value="sedan">Sedan</option>
                                <option value="suv">SUV</option>
                                <option value="hatchback">Hatchback</option>
                            </select>
                        </div>
                    </div>
                    <div className="Addresses">
                        <div>
                            <input type="text" placeholder="Pickup Address" name="pickupAddress" value={formData.pickupAddress} onChange={handleInputChange} />
                        </div>
                        <div>
                            <input type="text" placeholder="Drop Address" name="dropAddress" value={formData.dropAddress} onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className="SubmitButton">
                    <button type='submit'>Submit</button>
                </div>
            </form>
        </div>
    );
}

export default OutstationDriverComponent;
