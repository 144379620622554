import React, { useState, useRef } from 'react';
import './Styles.css';
import PriceDetailSheet from './PriceDetailSheet';

function WeeklyDriverComponent() {
    const [showPriceDetail, setShowPriceDetail] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        mobileNumber: '',
        from_date: '',
        to_date: '',
        time: '',
        vehicleType: '',
        pickupAddress: '',
        dropAddress: '',
        tripType: 'One Way Trip',
    });
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedHours, setSelectedHours] = useState('');
    const dateFromInputRef = useRef(null);
    const dateToInputRef = useRef(null);
    const timeInputRef = useRef(null);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const togglePriceDetail = () => {
        setShowPriceDetail(!showPriceDetail);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            if (checked) {
                setSelectedDays([...selectedDays, name]);
            } else {
                setSelectedDays(selectedDays.filter(day => day !== name));
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleHoursChange = (e) => {
        const selectedHours = e.target.value;
        setSelectedHours(selectedHours);
        const amount = calculateAmount(selectedHours);
        setSelectedAmount(amount);
    };

    const calculateAmount = (selectedHours) => {
        switch (selectedHours) {
            case "3 Hrs":
                return 406;
            case "4 Hrs":
                return 492;
            case "5 Hrs":
                return 574;
            case "6 Hrs":
                return 637;
            case "7 Hrs":
                return 708;
            case "8 Hrs":
                return 783;
            case "9 Hrs":
                return 864;
            case "10 Hrs":
                return 946;
            case "11 Hrs":
                return 1038;
            case "12 Hrs":
                return 1110;
            default:
                return 0;
        }
    };

    const totalAmount = selectedAmount * selectedDays.length;

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formattedSelectedDays = selectedDays.join(', ');

        const dataToSend = { ...formData, selectedDays: formattedSelectedDays, amount: totalAmount, componentName: 'WeeklyDriverComponent', selectedHours };

        try {
            const response = await fetch('https://dddbackend.vercel.app/api/v1/createddd', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            });
            const responseData = await response.json();
            console.log(responseData);
            if (responseData.success) {
                alert("Form submitted successfully!");
                setFormSubmitted(true);
                setFormData({
                    fullName: '',
                    mobileNumber: '',
                    from_date: '',
                    to_date: '',
                    time: '',
                    vehicleType: '',
                    pickupAddress: '',
                    dropAddress: '',
                    tripType: 'One Way Trip',
                });
                setSelectedDays([]);
                setSelectedHours('');
            } else {
                alert("Fill form carefully");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDateFromInputFocus = () => {
        dateFromInputRef.current.type = 'date';
    };

    const handleDateFromInputBlur = () => {
        if (dateFromInputRef.current.value === '') {
            dateFromInputRef.current.type = 'text';
        }
    };

    const handleDateToInputFocus = () => {
        dateToInputRef.current.type = 'date';
    };

    const handleDateToInputBlur = () => {
        if (dateToInputRef.current.value === '') {
            dateToInputRef.current.type = 'text';
        }
    };

    const handleTimeInputFocus = () => {
        timeInputRef.current.type = 'time';
    };

    const handleTimeInputBlur = () => {
        if (timeInputRef.current.value === '') {
            timeInputRef.current.type = 'text';
        }
    };

    const isDaySelected = (day) => {
        return selectedDays.includes(day);
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            setSelectedDays([...selectedDays, name]);
        } else {
            setSelectedDays(selectedDays.filter(day => day !== name));
        }
    };

    return (
        <div className="WeeklyDriverComponent">
            <div className="CostEstimation"><h3>Cost Estimation For Weekly Driver</h3></div>
            <div className="CostPerHour">
                <div>
                    <h5>Daily Hours</h5>
                </div>
                <div className="CostPerDistanceDropDown">
                    <select onChange={handleHoursChange}>
                        <option value="" disabled selected>Select</option>
                        <option value="3 Hrs">3 Hrs</option>
                        <option value="4 Hrs">4 Hrs</option>
                        <option value="5 Hrs">5 Hrs</option>
                        <option value="6 Hrs">6 Hrs</option>
                        <option value="7 Hrs">7 Hrs</option>
                        <option value="8 Hrs">8 Hrs</option>
                        <option value="9 Hrs">9 Hrs</option>
                        <option value="10 Hrs">10 Hrs</option>
                        <option value="11 Hrs">11 Hrs</option>
                        <option value="12 Hrs">12 Hrs</option>
                    </select>
                </div>
            </div>
            <div className="WeeklyCheckBox">
                <div className='CheckBoxContent'>
                    <input type="checkbox" id="monday" name="monday" onChange={handleCheckboxChange} checked={isDaySelected('monday')} />
                    <label htmlFor="monday">Mon</label>
                </div>
                <div className='CheckBoxContent'>
                    <input type="checkbox" id="tuesday" name="tuesday" onChange={handleInputChange} checked={isDaySelected('tuesday')}/>
                    <label htmlFor="tuesday">Tue</label>
                </div>
                <div className='CheckBoxContent'>
                    <input type="checkbox" id="wednesday" name="wednesday" onChange={handleInputChange} checked={isDaySelected('wednesday')} />
                    <label htmlFor="wednesday">Wed</label>
                </div>
                <div className='CheckBoxContent'>
                    <input type="checkbox" id="thursday" name="thursday" onChange={handleInputChange} checked={isDaySelected('thrusday')} />
                    <label htmlFor="thursday">Thu</label>
                </div>
                <div className='CheckBoxContent'>
                    <input type="checkbox" id="friday" name="friday" onChange={handleInputChange} checked={isDaySelected('friday')} />
                    <label htmlFor="friday">Fri</label>
                </div>
                <div className='CheckBoxContent'>
                    <input type="checkbox" id="saturday" name="saturday" onChange={handleInputChange} checked={isDaySelected('saturday')} />
                    <label htmlFor="saturday">Sat</label>
                </div>
                <div className='CheckBoxContent'>
                    <input type="checkbox" id="sunday" name="sunday" onChange={handleInputChange} checked={isDaySelected('sunday')} />
                    <label htmlFor="sunday">Sun</label>
                </div>
            
            </div>

            <div className="Price">
                <div className="Ammount">{totalAmount}</div>
            </div>
            <div className="ViewButton">
                <div> <button onClick={togglePriceDetail}> view details </button></div>
            </div>
            {showPriceDetail && (
                <div className="ModalOverlay">
                    <div className="ModalContent">
                        <PriceDetailSheet />
                        <button className="CloseButton" onClick={togglePriceDetail}>X</button>
                    </div>
                </div>
            )}
            {formSubmitted}
            <form onSubmit={handleSubmit}>
                <div className="Form">
                    <div className="NameAndNumber">
                        <div>
                            <input type="text" placeholder="Full Name" name="fullName" value={formData.fullName} onChange={handleInputChange} />
                        </div>
                        <div>
                            <input type="tel" placeholder="Mobile Number" name="mobileNumber" value={formData.mobileNumber} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="WeeklyDateTimeAndVehicleType">
                        <div className='WeeklyDates'>
                            <input type="text" placeholder="From Date" name="from_date" ref={dateFromInputRef} onFocus={handleDateFromInputFocus} onBlur={handleDateFromInputBlur} value={formData.from_date} onChange={handleInputChange} />
                            <input type="text" placeholder="To Date" name="to_date" ref={dateToInputRef} onFocus={handleDateToInputFocus} onBlur={handleDateToInputBlur} value={formData.to_date} onChange={handleInputChange} />
                        </div>
                        <div className='WeeklyTimeAndVehicle'>
                            <input type="text" placeholder="Time" name="time" ref={timeInputRef} onFocus={handleTimeInputFocus} onBlur={handleTimeInputBlur} value={formData.time} onChange={handleInputChange} />
                            <select name="vehicleType" value={formData.vehicleType} onChange={handleInputChange}>
                                <option value="" disabled>Vehicle Type</option>
                                <option value="sedan">Sedan</option>
                                <option value="suv">SUV</option>
                                <option value="hatchback">Hatchback</option>
                            </select>
                        </div>
                    </div>
                    <div className="Addresses">
                        <div>
                            <input type="text" name="pickupAddress" placeholder="Pickup Address" value={formData.pickupAddress} onChange={handleInputChange} />
                        </div>
                        <div>
                            <input type="text" name="dropAddress" placeholder="Drop Address" value={formData.dropAddress} onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className="SubmitButton">
                    <button type='submit'>Submit</button>
                </div>
            </form>
        </div>
    );
}

export default WeeklyDriverComponent;
