import React, { useState } from 'react';
import WelcomeComponent from './WelcomeComponent';
import HourlyDriverComponent from './HourlyDriverComponent';
import WeeklyDriverComponent from './WeeklyDriverComponent';
import MonthlyDriverComponent from './MonthlyDriverComponent';
import OutstationDriverComponent from './OutstationDriverComponent';
import Refer from './Refer';
import Reviews from './Reviews';
import Track from './Track';
import logo from '../Assets/dddlogo2.jpeg';
import ReferIcon from '../Assets/refer_new.png';
import ReviewIcon from '../Assets/reviewsicon.png';
import TrackIcon from '../Assets/trackicon.png';
import './Styles.css';



const Main = () => {
    const [currentComponent, setCurrentComponent] = useState('welcome');
    const [previousComponent, setPreviousComponent] = useState(null);
    const [showMenu, setShowMenu] = useState(false);

    const renderComponent = () => {
        switch (currentComponent) {
            case 'welcome':
                return <WelcomeComponent />;
            case 'hourly':
                return <HourlyDriverComponent />;
            case 'weekly':
                return <WeeklyDriverComponent />;
            case 'monthly':
                return <MonthlyDriverComponent />;
            case 'outstation':
                return <OutstationDriverComponent />;
            case 'refer':
                return <Refer />;
            case 'reviews':
                return <Reviews />;
            case 'track':
                return <Track />;
            default:
                return null;
        }
    };

    const handleBackButtonClick = () => {
        if (previousComponent) {
            setCurrentComponent(previousComponent);
            setPreviousComponent(null);
        } else {
            setCurrentComponent('welcome');
        }
    };

    const handleMoreButtonClick = () => {
        setShowMenu(!showMenu);
    };

    const handleMenuItemClick = (component) => {
        setPreviousComponent(currentComponent);
        setCurrentComponent(component);
        setShowMenu(false);
    };



    return (
        <div className="CompleteLayout">
            <div className="Main">
                <div className="LeftContainer" >
                    <div className="NameAndLogo" onClick={() => setCurrentComponent('welcome')}>
                        <img src={logo} alt="Logo" />
                        <span>Delhi Drive & Drivers</span>
                    </div>
                    <div className="LeftContainerButtons">
                        <button onClick={() => setCurrentComponent('hourly')}>Hourly Driver </button>
                        <button onClick={() => setCurrentComponent('weekly')}>Weekly Driver</button>
                        <button onClick={() => setCurrentComponent('monthly')}>Monthly Driver</button>
                        <button onClick={() => setCurrentComponent('outstation')}>Outstation Driver</button>
                    </div>
                </div>

                <div className="RightContainer">
                    <div className="AllComponentsRenderMaster">
                        <div className="AllComponentsRenderHere">
                            {renderComponent()}
                        </div>
                    </div>
                </div>

                <div className="BackAndMoreButton">

                    {currentComponent === 'welcome' ? (
                        <div className="MoreContainer">
                            <button onClick={handleMoreButtonClick} className="MoreButtonContainer">More</button>
                            {showMenu && (
                                <div className="dropdown-menu">
                                    <button onClick={() => handleMenuItemClick('refer')}>Refer</button>
                                    <button onClick={() => handleMenuItemClick('reviews')}>Reviews</button>
                                    <button onClick={() => handleMenuItemClick('track')}>Track</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <button onClick={handleBackButtonClick} className="BackButtonContainer">Back</button>
                    )}
                </div>
            </div>

            <div className="Footer">
                <div className='ReferIcon' onClick={() => setCurrentComponent('refer')}>
                    <img src={ReferIcon} alt='Refer' />
                    Refer
                </div>
                <div className='ReviewIcon' onClick={() => setCurrentComponent('reviews')}>
                    <img src={ReviewIcon} alt='Reviews' />
                    Reviews
                </div>
                <div className='TrackingIcon' onClick={() => setCurrentComponent('track')}>
                    <img src={TrackIcon} alt='Track' />
                    Track
                </div>
            </div>
        </div>
    );
}

export default Main;
