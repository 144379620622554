import './Styles.css';

function Track() {
    return (
        <div className="Refer">
            <div className="InsideRefer">
                <div className="ReferBoxComment"> <span>Track Your</span></div>
                <div className="MobileInputBox">
                    <input type="mobile"
                        placeholder="Enter Mobile Number"
                        alt="Mobile Number "
                    />
                </div>
                <div className="SubmitButton">
                    <button>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default Track;