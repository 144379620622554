import './Styles.css';

function Refer() {
    return (
        <div className="Refer">
            <div className="InsideRefer">
                <div className="ReferBoxComment"> <span>Refer to other Peoples</span></div>
                <div className="MobileInputBox">
                    <input type="mobile"
                        placeholder="Enter Mobile Number"
                        alt="Mobile Number "
                    />
                </div>
                <div className="SubmitButton">
                    <button>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default Refer;