import React, { useState, useRef } from 'react';
import './Styles.css';
import PriceDetailSheet from './PriceDetailSheet';

function HourlyDriverComponent() {
    const [showPriceDetail, setShowPriceDetail] = useState(false);
    const [selectedHours, setSelectedHours] = useState("");
    const dateInputRef = useRef(null); // Ref for the date input
    const timeInputRef = useRef(null); // Ref for the time input
    const [formSubmitted, setFormSubmitted] = useState(false); // State variable to track form submission
    const [tripType, setTripType] = useState("One Way Trip");
    const [formData, setFormData] = useState({
        "fullName": '',
        "mobileNumber": '',
        "date": '',
        "time": '',
        "vehicleType": '',
        "pickupAddress": '',
        "dropAddress": ''
    });

    const togglePriceDetail = () => {
        setShowPriceDetail(!showPriceDetail);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleTripTypeChange = (type) => {
        setTripType(type);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedDistance = document.querySelector('.CostPerHourDropDown select').value;
        const amount = calculateAmount();
        const dataToSend = { ...formData, tripType, selectedDistance, amount, componentName: 'HourlyDriverComponent' };
        // Send formData to backend or perform any other action here
        try {
            const response = await fetch('https://dddbackend.vercel.app/api/v1/createddd', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            });
            const responseData = await response.json();
            console.log(responseData);
            if (responseData.success) {
                alert("Entry submitted successfully!");
                setFormSubmitted(true); // Set formSubmitted to true after form submission
                // Reset form fields
                setFormData({
                    "fullName": '',
                    "mobileNumber": '',
                    "date": '',
                    "time": '',
                    "vehicleType": '',
                    "pickupAddress": '',
                    "dropAddress": ''
                });
                setSelectedHours("");
            } else {
                alert("Fill form carefully");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleHoursChange = (e) => {
        const selectedHours = e.target.value;
        setSelectedHours(selectedHours);
    };

    const calculateAmount = () => {
        switch (selectedHours) {
            case "3 Hrs":
                return 406;
            case "4 Hrs":
                return 492;
            case "5 Hrs":
                return 574;
            case "6 Hrs":
                return 637;
            case "7 Hrs":
                return 708;
            case "8 Hrs":
                return 783;
            case "9 Hrs":
                return 864;
            case "10 Hrs":
                return 946;
            case "11 Hrs":
                return 1038;
            case "12 Hrs":
                return 1110;
            default:
                return 0;
        }
    };

    const handleDateInputFocus = () => {
        dateInputRef.current.type = 'date'; // Change input type to date when focused
    };

    const handleDateInputBlur = () => {
        if (dateInputRef.current.value === '') {
            dateInputRef.current.type = 'text'; // Change input type back to text if no date is selected
        }
    };

    const handleTimeInputFocus = () => {
        timeInputRef.current.type = 'time'; // Change input type to time when focused
    };

    const handleTimeInputBlur = () => {
        if (timeInputRef.current.value === '') {
            timeInputRef.current.type = 'text'; // Change input type back to text if no time is selected
        }
    };

    return (
        <div className="HourlyDriverComponent">
            <div className="CostEstimation"><h3>Cost Estimation For Hourly Driver</h3></div>
            <div className="TripTypeButton">
                <button onClick={() => handleTripTypeChange("One Way Trip")} className={tripType === "One Way Trip" ? "active" : ""}>One Way Trip</button>
                <button onClick={() => handleTripTypeChange("Round Trip")} className={tripType === "Round Trip" ? "active" : ""}>Round Trip</button>
            </div>
            <div className="CostPerHour">
                <div><h5>Hours </h5></div>
                <div className="CostPerHourDropDown">
                    <select name="hours" onChange={handleHoursChange}>
                        <option value="" disabled selected>Select</option>
                        <option value="3 Hrs">3 Hrs</option>
                        <option value="4 Hrs">4 Hrs</option>
                        <option value="5 Hrs">5 Hrs</option>
                        <option value="6 Hrs">6 Hrs</option>
                        <option value="7 Hrs">7 Hrs</option>
                        <option value="8 Hrs">8 Hrs</option>
                        <option value="9 Hrs">9 Hrs</option>
                        <option value="10 Hrs">10 Hrs</option>
                        <option value="11 Hrs">11 Hrs</option>
                        <option value="12 Hrs">12 Hrs</option>
                    </select>
                </div>
            </div>
            <div className="Price">
                <div className="Ammount"> {calculateAmount()} Rs </div>
            </div>
            <div className="ViewButton">
                <div> <button onClick={togglePriceDetail}> view details </button></div>
            </div>
            {showPriceDetail && (
                <div className="ModalOverlay">
                    <div className="ModalContent">
                        <PriceDetailSheet />
                        <button className="CloseButton" onClick={togglePriceDetail}>X</button> {/* Close button */}
                    </div>
                </div>
            )}
            {formSubmitted}
            <form onSubmit={handleSubmit}>
                <div className="Form">
                    <div className="NameAndNumber">
                        <div><input type="text" name="fullName" placeholder="Full Name*" onChange={handleInputChange} value={formData.fullName} /></div>
                        <div><input type="tel" name="mobileNumber" placeholder="Mobile Number" onChange={handleInputChange} value={formData.mobileNumber} /></div>
                    </div>
                    <div className="DateTimeAndVehicleType">
                        <div className='HourlyDateAndTime'>
                            <input ref={dateInputRef} type="text" name="date" placeholder="From Date" onChange={handleInputChange} onFocus={handleDateInputFocus} onBlur={handleDateInputBlur} value={formData.date} />
                            <input ref={timeInputRef} type="text" name="time" placeholder="Time" onChange={handleInputChange} onFocus={handleTimeInputFocus} onBlur={handleTimeInputBlur} value={formData.time} />
                        </div>
                        <div className='HourlyVehicleType'>
                            <select name="vehicleType" onChange={handleInputChange} value={formData.vehicleType}>
                                <option value="" disabled selected>Vehicle Type</option>
                                <option value="sedan">Sedan</option>
                                <option value="suv">SUV</option>
                                <option value="hatchback">Hatchback</option>
                            </select>
                        </div>
                    </div>
                    <div className="Addresses">
                        <div><input type="text" name="pickupAddress" placeholder="Pickup Address" onChange={handleInputChange} value={formData.pickupAddress} /></div>
                        <div><input type="text" name="dropAddress" placeholder="Drop Address" onChange={handleInputChange} value={formData.dropAddress} /></div>
                    </div>
                </div>
                <div className="SubmitButton">
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default HourlyDriverComponent;
