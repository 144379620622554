function PriceDetailSheet() {
    return (
        <div>
            <div>
                <div className="PriceDetails1"><h3>Pricing</h3></div>
                <div><span> {'>'} Prices shown are GST Inclusive & Hour's based</span></div>
                <div><span> {'>'} Rs 1.5 Per Minute to be charged incase actual travel time exceeds your package time</span></div>
                <div><span> {'>'} Rs 150 Night travel charges (NTA) to be applied in case you travel in between 10:00 PM To 06:00 AM</span></div>
                
                <div className="PriceDetails2"><h3>Things To Know</h3></div>
                <div><span> {'>'} Nearby driver in 90 Mins at your doorstep</span></div>
                <div><span> {'>'} Experienced Driver</span></div>
                <div><span> {'>'} Pay at the end of trip</span></div>
                <div><span> {'>'} You need not to provide food or travel expenses to the driver</span></div>
                
                
                
                
            </div>
        </div>
    );
}

export default PriceDetailSheet;