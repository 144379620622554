import React, { useState, useRef } from 'react';
import './Styles.css';
import PriceDetailSheet from './PriceDetailSheet';

function MonthlyDriverComponent() {
    const [showPriceDetail, setShowPriceDetail] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        mobileNumber: '',
        from_date: '',
        to_date: '',
        time: '',
        vehicleType: '',
        pickupAddress: '',
        dropAddress: '',
        tripType: '',
    });
    const [selectedHours, setSelectedHours] = useState(8); // Default selected hours
    const [selectedDays, setSelectedDays] = useState(22); // Default selected days
    const dateFromInputRef = useRef(null); // Ref for the from_date input
    const dateToInputRef = useRef(null); // Ref for the to_date input
    const timeInputRef = useRef(null); // Ref for the time input
    const [formSubmitted, setFormSubmitted] = useState(false); // State variable to track form submission

    const togglePriceDetail = () => {
        setShowPriceDetail(!showPriceDetail);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare data to send to backend
        const dataToSend = { ...formData, selectedHours,amount, selectedDays, componentName: 'MonthlyDriverComponent' };

        // Send data to backend
        try {
            const response = await fetch('https://dddbackend.vercel.app/api/v1/createddd', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            });
            const responseData = await response.json();
            console.log(responseData);
            if (responseData.success) {
                setFormSubmitted(true);
                resetForm(); // Reset the form fields
                alert('Entry submitted');
            } else {
                alert('Fill the form carefully');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const resetForm = () => {
        setFormData({
            fullName: '',
            mobileNumber: '',
            from_date: '',
            to_date: '',
            time: '',
            vehicleType: '',
            pickupAddress: '',
            dropAddress: '',
            tripType: '',
        });
        setSelectedHours(8);
        setSelectedDays(22);
        dateFromInputRef.current.value = '';
        dateToInputRef.current.value = '';
        timeInputRef.current.value = '';
    };

    const handleHourChange = (e) => {
        const selectedHours = parseInt(e.target.value);
        setSelectedHours(selectedHours);
    };

    const handleDayChange = (e) => {
        const selectedDays = parseInt(e.target.value);
        setSelectedDays(selectedDays);
    };

    const handleDateFromInputFocus = () => {
        dateFromInputRef.current.type = 'date'; // Change input type to date when focused
    };

    const handleDateFromInputBlur = () => {
        if (dateFromInputRef.current.value === '') {
            dateFromInputRef.current.type = 'text'; // Change input type back to text if no date is selected
        }
    };

    const handleDateToInputFocus = () => {
        dateToInputRef.current.type = 'date'; // Change input type to date when focused
    };

    const handleDateToInputBlur = () => {
        if (dateToInputRef.current.value === '') {
            dateToInputRef.current.type = 'text'; // Change input type back to text if no date is selected
        }
    };

    const handleTimeInputFocus = () => {
        timeInputRef.current.type = 'time'; // Change input type to time when focused
    };

    const handleTimeInputBlur = () => {
        if (timeInputRef.current.value === '') {
            timeInputRef.current.type = 'text'; // Change input type back to text if no time is selected
        }
    };

    const calculateAmount = () => {
        const baseAmount = 16000; // Base amount for 8 hours and 22 days
        const additionalHours = selectedHours - 8;
        const additionalDays = selectedDays - 22;
        const extraAmountPerHour = 500;
        const extraAmountPerDay = 500; // You can adjust this if needed

        const totalAmount = baseAmount + (additionalHours * extraAmountPerHour) + (additionalDays * extraAmountPerDay);
        return totalAmount;
    };
    const amount = calculateAmount();

    return (
        <div className="MonthlyDriverComponent">
            <div className="CostEstimation"><h3>Cost Estimation For Monthly Driver</h3></div>
            <div className="CostPerHour">
                <div>
                    <h5>Daily Hours</h5>
                </div>
                <div className="CostPerDistanceDropDown">
                    <select value={selectedHours} onChange={handleHourChange}>
                        <option value="8">8 Hrs</option>
                        <option value="9">9 Hrs</option>
                        <option value="10">10 Hrs</option>
                        <option value="11">11 Hrs</option>
                        <option value="12">12 Hrs</option>
                    </select>
                </div>
            </div>
            <div className="CostPerHour">
                <div>
                    <h5>No. Of Days</h5>
                </div>
                <div className="CostPerDistanceDropDown">
                    <select value={selectedDays} onChange={handleDayChange}>
                        <option value="22">22 Days</option>
                        <option value="23">23 Days</option>
                        <option value="24">24 Days</option>
                        <option value="25">25 Days</option>
                        <option value="26">26 Days</option>
                        <option value="27">27 Days</option>
                        <option value="28">28 Days</option>
                        <option value="29">29 Days</option>
                        <option value="30">30 Days</option>
                        <option value="31">31 Days</option>
                    </select>
                </div>
            </div>
            <div className="Price">
                <div className="Amount">{amount} Rs</div>
            </div>
            <div className="ViewButton">
                <div> <button onClick={togglePriceDetail}> view details </button></div>
            </div>
            {showPriceDetail && (
                <div className="ModalOverlay">
                    <div className="ModalContent">
                        <PriceDetailSheet />
                        <button className="CloseButton" onClick={togglePriceDetail}>X</button> {/* Close button */}
                    </div>
                </div>
            )}
            {formSubmitted}
            <form onSubmit={handleSubmit}>
                <div className="Form">
                    <div className="NameAndNumber">
                        <div>
                            <input type="text" placeholder="Full Name" name="fullName" value={formData.fullName} onChange={handleInputChange} />
                        </div>
                        <div>
                            <input type="tel" placeholder="Mobile Number" name="mobileNumber" value={formData.mobileNumber} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="MonthlyDateTimeAndVehicleType">
                        <div className='MonthlyDates'>
                            <input type="text" placeholder="From Date" name="from_date" ref={dateFromInputRef} onFocus={handleDateFromInputFocus} onBlur={handleDateFromInputBlur} value={formData.from_date} onChange={handleInputChange} />
                            <input type="text" placeholder="To Date" name="to_date" ref={dateToInputRef} onFocus={handleDateToInputFocus} onBlur={handleDateToInputBlur} value={formData.to_date} onChange={handleInputChange} />
                        </div>
                        <div className='MonthlyTimeAndVehicle' >
                            <input type="text" placeholder="Time" name="time" ref={timeInputRef} onFocus={handleTimeInputFocus} onBlur={handleTimeInputBlur} value={formData.time} onChange={handleInputChange} />
                            <select name="vehicleType" value={formData.vehicleType} onChange={handleInputChange}>
                                <option value="" disabled selected>Vehicle Type</option>
                                <option value="sedan">Sedan</option>
                                <option value="suv">SUV</option>
                                <option value="hatchback">Hatchback</option>
                            </select>
                        </div>
                    </div>
                    <div className="Addresses">
                        <div>
                            <input type="text" placeholder="Pickup Address" name="pickupAddress" value={formData.pickupAddress} onChange={handleInputChange} />
                        </div>
                        <div>
                            <input type="text" placeholder="Drop Address" name="dropAddress" value={formData.dropAddress} onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className="SubmitButton">
                    <button type='submit'>Submit</button>
                </div>
            </form>
        </div>
    );
}

export default MonthlyDriverComponent;
