import React, { useState } from 'react';
import './Admin.css';

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [data, setData] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://dddbackend.vercel.app/api/admin/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
            const responseData = await response.json();
            if (response.ok) {
                setData(responseData.dddsData);
            } else {
                setError(responseData.message || 'Login failed');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred while logging in');
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return ''; // Return empty string if dateString is null or undefined
        const formattedDate = dateString.substring(0, 10); // Extract the date part
        return formattedDate;
    };


    return (
        <div className="admin-login-container">
            {!data ? (
                <div className="WholeLoginScreen">
                    <div className="LoginPage">
                        <div className="LoginHeading">
                            <h4>Enter Your Login Details</h4>
                        </div>

                        <form className="InputBox" onSubmit={handleLogin}>
                            <input type="text" id='InputBox1' placeholder="Enter Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                            <br />
                            <input type="password" id='InputBox2' placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <br />
                            <button type="submit" id="LoginButton">Login</button>
                        </form>

                        {error && <div className="error-message">{error}</div>}
                    </div>
                </div>


            ) : (
                <div className="data-display">
                    <h2>Welcome, {username}!</h2>
                    <h3>Bookings Till Now</h3>
                    <div className="card-container">
                        {data.map((item) => (
                            <div key={item._id} className="card">
                                {item.fullName && <div><strong> Full Name: </strong>{item.fullName}</div>}
                                {item.mobileNumber && <div><strong> Mobile Number: </strong> {item.mobileNumber}</div>}
                                {item.date && <div><strong> Trip Date: </strong> {formatDate(item.date)}</div>}
                                {item.from_date && <div><strong> From Date: </strong> {formatDate(item.from_date)}</div>}
                                {item.to_date && <div><strong> To Date: </strong> {formatDate(item.to_date)}</div>}
                                {item.time && <div><strong> Trip Time: </strong> {item.time}</div>}
                                {item.vehicleType && <div><strong> Vehicle Type: </strong> {item.vehicleType}</div>}
                                {item.pickupAddress && <div><strong> Pickup Address: </strong> {item.pickupAddress}</div>}
                                {item.dropAddress && <div><strong> Drop Address: </strong> {item.dropAddress}</div>}
                                {item.tripType && <div><strong> Trip Type: </strong> {item.tripType}</div>}
                                {item.componentName && <div><strong> Booking Type: </strong> {item.componentName}</div>}
                                {item.selectedHours && <div><strong> Hours: </strong> {item.selectedHours}</div>}
                                {item.amount && <div><strong> Amount: </strong> {item.amount}</div>}
                                {item.selectedDays && Array.isArray(item.selectedDays) && item.selectedDays.length > 0 && (
                                    <div><strong> Selected Days: </strong> {item.selectedDays.join(', ')}</div>
                                )}
                            </div>
                        ))}

                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminLogin;
