import './App.css';
import {Routes,Route} from 'react-router-dom';
import Main from './Components/Main';
import Admin from './Components/Admin/AdminLogin';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/admin' element={<Admin />} />
      </Routes>
    </div>
  );
}

export default App;
