import './Styles.css'
import LeftHandimage from '../Assets/hand-left-icon.png';
import TrainedIcon from '../Assets/trainedicon.png';
import ExperienceIcon from '../Assets/experiencedicon.png';
import PunctualIcon from '../Assets/punctualicon.png';
import MaskIcon from '../Assets/maskicons.png';
import Icon247 from '../Assets/247icon.png';

function WelcomeComponent() {
    return (
        <div className="WelcomeComponent">
            <div className="WelcomeHeading">
                <h5>I Require Driver For ?</h5>
            </div>
            <div className="LeftHandPng">
                <img src={LeftHandimage} alt="Click On Left Buttons" />
            </div>
            <div className="DescriptionBox">
                <span>We ensure briefing of driver before onboarding. </span>
                <span>Stay safe with DDD </span>
            </div>
            <div className="ProvidingServices">
                <div className="AllServices">
                    <div className="service-item">
                        <img src={TrainedIcon} alt="Trained" />
                        <h5>Trained</h5>
                    </div>
                    <div className="service-item">
                        <img src={ExperienceIcon} alt="Experienced" />
                        <h5>Experienced</h5>
                    </div>
                    <div className="service-item">
                        <img src={PunctualIcon} alt="Punctual" />
                        <h5>Punctual</h5>
                    </div>
                    <div className="service-item">
                        <img src={MaskIcon} alt="Safety" />
                        <h5>Safety</h5>
                    </div>
                    <div className="service-item">
                        <img src={Icon247} alt="24*7" />
                        <h5>24*7</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WelcomeComponent;
