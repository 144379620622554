import './Styles.css';

const Reviews = () => {

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(<span key={i} className={i <= rating ? 'star filled' : 'star'}>&#9733;</span>);
        }
        return stars;
    };


    return (
        <div className="AllReviews">
            <div className="ReviewTaking">
                <input type='text' id="ReviewTextArea" placeholder='Enter Your Thought'></input>
                <input type="mobile" alt="Mobile Number" placeholder='Enter Mobile Number' id="MobileNumberArea" />
                <button className="ReviewSubmitButton">Submit</button>
            </div>

            <div className="ReviewsContainer">
                <div className="Reviews">
                    <div className="ReviewHeader">
                        {/* <div className="avatar">J</div> */}
                        <div className="ReviewDetails">
                            <div>
                                <div className="name">John Doe</div>
                                <div className="city">New York</div>
                            </div>
                            <div>
                                <div className="rating">{renderStars(4)}</div>
                                <div className="time">2 days ago</div>
                            </div>
                        </div>
                    </div>
                    <div className="ReviewComment">Great service! The driver was punctual and professional.</div>
                </div>
                <div className="Reviews">
                    <div className="ReviewHeader">
                        {/* <div className="avatar">J</div> */}
                        <div className="ReviewDetails">
                            <div> <div className="name">Jane Smith</div>
                                <div className="city">Los Angeles</div>
                            </div>
                            <div>
                                <div className="rating">{renderStars(5)}</div>
                                <div className="time">1 week ago</div>
                            </div>
                        </div>
                    </div>
                    <div className="ReviewComment">Excellent experience! The vehicle was clean and comfortable.</div>
                </div>
                <div className="Reviews">
                    <div className="ReviewHeader">
                        {/* <div className="avatar">B</div> */}
                        <div className="ReviewDetails">
                            <div>
                                <div className="name">Bob Johnson</div>
                                <div className="city">Chicago</div>
                            </div>
                            <div>
                                <div className="rating">{renderStars(3)}</div>
                                <div className="time">3 weeks ago</div>
                            </div>
                        </div>
                    </div>
                    <div className="ReviewComment">Average service. The driver was friendly but the vehicle was a bit old.</div>
                </div>
                <div className="Reviews">
                    <div className="ReviewHeader">
                        {/* <div className="avatar">B</div> */}
                        <div className="ReviewDetails">
                            <div>
                                <div className="name">Bob Johnson</div>
                                <div className="city">Chicago</div>
                            </div>
                            <div>
                                <div className="rating">{renderStars(3)}</div>
                                <div className="time">3 weeks ago</div>
                            </div>
                        </div>
                    </div>
                    <div className="ReviewComment">Average service. The driver was friendly but the vehicle was a bit old.</div>
                </div>
                <div className="Reviews">
                    <div className="ReviewHeader">
                        {/* <div className="avatar">B</div> */}
                        <div className="ReviewDetails">
                            <div>
                                <div className="name">Bob Johnson</div>
                                <div className="city">Chicago</div>
                            </div>
                            <div>
                                <div className="rating">{renderStars(3)}</div>
                                <div className="time">3 weeks ago</div>
                            </div>
                        </div>
                    </div>
                    <div className="ReviewComment">Average service. The driver was friendly but the vehicle was a bit old.</div>
                </div>
                <div className="Reviews">
                    <div className="ReviewHeader">
                        {/* <div className="avatar">B</div> */}
                        <div className="ReviewDetails">
                            <div>
                                <div className="name">Bob Johnson</div>
                                <div className="city">Chicago</div>
                            </div>
                            <div>
                                <div className="rating">{renderStars(3)}</div>
                                <div className="time">3 weeks ago</div>
                            </div>
                        </div>
                    </div>
                    <div className="ReviewComment">Average service. The driver was friendly but the vehicle was a bit old.</div>
                </div>
                <div className="Reviews">
                    <div className="ReviewHeader">
                        {/* <div className="avatar">B</div> */}
                        <div className="ReviewDetails">
                            <div>
                                <div className="name">Bob Johnson</div>
                                <div className="city">Chicago</div>
                            </div>
                            <div>
                                <div className="rating">{renderStars(3)}</div>
                                <div className="time">3 weeks ago</div>
                            </div>
                        </div>
                    </div>
                    <div className="ReviewComment">Average service. The driver was friendly but the vehicle was a bit old.</div>
                </div>
            </div>
        </div>
    );
}

export default Reviews;
